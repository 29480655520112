import React from "react"
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaSoundcloud, FaSpotify, FaApple } from "react-icons/fa"
import { SiBeatport } from "react-icons/si";

export const socialMenuItems = [
  {
    icon: <FaSpotify/>,
    url: "https://open.spotify.com/artist/54uCwjpagoWFwtUN1RnTQu",
    name: "Spotify",
  },
  {
    icon: <FaApple/>,
    url: "https://music.apple.com/us/artist/dream-tonic/1477355527",
    name: "AppleMusic",
  },
  {
    icon: <FaYoutube/>,
    url: "https://www.youtube.com/channel/UCgj8CXSsg47gS3EN1HvwEhQ",
    name: "Youtube",
  },
  {
    icon: <FaSoundcloud/>,
    url: "https://soundcloud.com/dreamtonicmusic",
    name: "SoundCloud",
  },
  {
    icon: <SiBeatport/>,
    url: "https://www.beatport.com/artist/dream-tonic/1010238",
    name: "Beatport",
  },
  {
    icon: <FaInstagram/>,
    url: "https://www.instagram.com/dreamtonicmusic",
    name: "Instagram",
  },
  {
    icon: <FaFacebook/>,
    url: "https://www.facebook.com/dreamtonicmusic",
    name: "Facebook",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/dreamtonicmusic",
    name: "Twitter",
  },
]

// export const footerMenuItems = [
// ]
