export const mainMenuItems = [
  {
    path: "/",
    text: "about",
  },
  {
    path: "/",
    text: "music",
  },
  {
    path: "/",
    text: "videos",
  },
  {
    path: "/",
    text: "photos",
  },
  {
    path: "/",
    text: "press",
  },
  {
    path: "/",
    text: "updates",
  },
  {
    path: "/",
    text: "subscribe",
  },
  {
    path: "/",
    text: "contact",
  },
]
